<template>
  <div class="components-essentials-progress-container">
    <div class="backdrop"></div>
    <div class="front" :style="{ width: percent }"></div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      required: true
    }
  },

  computed: {
    percent() {
      return this.value + '%'
    }
  }
}
</script>

<style lang="scss" scoped>
.components-essentials-progress-container {
  @apply h-6 relative
}

.backdrop {
  background: #F0F0E9;
  @apply w-full h-full absolute left-0 rounded-full;
}

.front {
  @apply bg-theme-secondary h-full absolute rounded-full text-white text-sm flex justify-end items-center;
}
</style>
